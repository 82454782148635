const matrRouter = {
    list:{
        path: '/puca_list',
        name: 'MatrList',
        component: () => import('@/views/PurcManagement/MatrManage/MatrList.vue'),
        meta:{
            title: '材料订单 列表'
        },
    },
    add: {
        path: '/puca_add',
        name: 'MatrAdd',
        component: () => import('@/views/PurcManagement/MatrManage/MatrAdd.vue'),
        meta:{
            title: '材料订单 新增'
        },
    },
    edit: {
        path: '/puca_edit',
        name: 'MatrEdit',
        component: () => import('@/views/PurcManagement/MatrManage/MatrEdit.vue'),
        meta:{
            title: '材料订单 编辑'
        },
    },
    push1: {
        path: '/puca_push_list_9001',
        name: 'MatrPush9001',
        component: () => import('@/views/PurcManagement/MatrManage/MatrPush9001.vue'),
        meta: {
            title: '材料订单变更提醒'
        },
    },
    push2: {
        path: '/puca_push_list_9002',
        name: 'MatrPush9002',
        component: () => import('@/views/PurcManagement/MatrManage/MatrPush9002.vue'),
        meta: {
            title: '补货单新增提醒'
        },
    },
}
export default matrRouter
