const qrclRouter = {
  list:{
      path: '/qrcl_list',
      name: 'QrclList',
      component: () => import('@/views/MessageManagement/QrclManage/QrclList.vue'),
      meta:{
          title: '二维码 列表'
      },
  },
}
export default qrclRouter