const shweRouter = {
  list: {
    path: '/shwe_list',
    name: 'ShweList',
    component: () => import('@/views/ProduceManagement/ShweManage/ShweList.vue'),
    meta: {
      title: '利器收发 列表'
    }
  },
  shweDetail: {
    path: '/shwe_detail',
    name: 'ShweDetail',
    component: () => import('@/views/ProduceManagement/ShweManage/ShweDetail.vue'),
    meta: {
      title: '利器收发 详情'
    }
  },
  push1: {
    path: '/shwe_push_list_9001',
    name: 'ShwePush9001',
    component: () => import('@/views/ProduceManagement/ShweManage/ShwePush9001.vue'),
    meta: {
      title: '利器数量异常'
    }
  },
  push2: {
    path: '/shwe_push_list_9002',
    name: 'ShwePush9002',
    component: () => import('@/views/ProduceManagement/ShweManage/ShwePush9002.vue'),
    meta: {
      title: '未回收数量'
    }
  },
  push3: {
    path: '/shwe_push_list_9003',
    name: 'ShwePush9003',
    component: () => import('@/views/ProduceManagement/ShweManage/ShwePush9003.vue'),
    meta: {
      title: '断针回收记录'
    }
  }
};
export default shweRouter;
