const msinRouter = {
  list:{
      path: '/msin_list',
      name: 'MsinList',
      component: () => import('@/views/StockManagement/MsinManage/MsinList.vue'),
      meta:{
          title: '物料入库单 列表'
      },
  },
  add: {
      path: '/msin_add',
      name: 'MsinAdd',
      component: () => import('@/views/StockManagement/MsinManage/MsinAdd.vue'),
      meta:{
          title: '物料入库单 新增'
      },
  },
  edit: {
      path: '/msin_edit',
      name: 'MsinEdit',
      component: () => import('@/views/StockManagement/MsinManage/MsinEdit.vue'),
      meta:{
          title: '物料入库单 编辑'
      },
  }
}
export default msinRouter