const ppotRouter = {
  list:{
      path: '/ppot_list',
      name: 'PpotList',
      component: () => import('@/views/StockManagement/PpotManage/PpotList.vue'),
      meta:{
          title: '成品待出库 列表'
      },
  },
  // add: {
  //     path: '/ppot_add',
  //     name: 'PpotAdd',
  //     component: () => import('@/views/PurchaseManagement/PpotManage/PpotAdd.vue'),
  //     meta:{
  //         title: '物料采购 新增'
  //     },
  // },
  // edit: {
  //     path: '/ppot_edit',
  //     name: 'PpotEdit',
  //     component: () => import('@/views/PurchaseManagement/PpotManage/PpotEdit.vue'),
  //     meta:{
  //         title: '物料采购 编辑'
  //     },
  // }
}
export default ppotRouter