const ppinRouter = {
  list:{
      path: '/ppin_list',
      name: 'PpinList',
      component: () => import('@/views/StockManagement/PpinManage/PpinList.vue'),
      meta:{
          title: '成品待入库 列表'
      },
  },
  // add: {
  //     path: '/ppin_add',
  //     name: 'PpinAdd',
  //     component: () => import('@/views/PurchaseManagement/PpinManage/PpinAdd.vue'),
  //     meta:{
  //         title: '物料采购 新增'
  //     },
  // },
  // edit: {
  //     path: '/ppin_edit',
  //     name: 'PpinEdit',
  //     component: () => import('@/views/PurchaseManagement/PpinManage/PpinEdit.vue'),
  //     meta:{
  //         title: '物料采购 编辑'
  //     },
  // }
}
export default ppinRouter