const popuRouter = {
    list:{
        path: '/popu_list',
        name: 'PopuList',
        component: () => import('@/views/PurcManagement/PopuManage/PopuList.vue'),
        meta:{
            title: '成品订单 列表'
        },
    },
    edit:{
        path: '/popu_edit',
        name: 'PopuEdit',
        component: () => import('@/views/PurcManagement/PopuManage/PopuEdit.vue'),
        meta:{
            title: '成品订单 编辑'
        },
    },
    push1: {
        path: '/popu_push_list_9001',
        name: 'getPropucaByChange',
        component: () => import('@/views/PurcManagement/PopuManage/Popu9001.vue'),
        meta: {
            title: '成品订单变更提醒'
        },
    },
}
export default popuRouter
