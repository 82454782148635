const myacRouter = {
    list:{
        path: '/myac_list',
        name: 'MyacList',
        component: () => import('@/views/SystemManagement/MyacManage/MyacList.vue'),
        meta:{
            title: '我的账套 列表'
        },
    },
}
export default myacRouter