const mainRouter = {
    list:{
        path: '/main_list',
        name: 'MainList',
        component: () => import('@/views/StockManagement/MainManage/MainListNew.vue'),
        meta:{
            title: '物料库存 列表'
        },
    },
    // add: {
    //     path: '/main_add',
    //     name: 'MainAdd',
    //     component: () => import('@/views/StockManagement/MainManage/MainAdd.vue'),
    //     meta:{
    //         title: '物料库存 新增'
    //     },
    // },
    // edit: {
    //     path: '/main_edit',
    //     name: 'MainEdit',
    //     component: () => import('@/views/StockManagement/MainManage/MainEdit.vue'),
    //     meta:{
    //         title: '物料库存 编辑'
    //     },
    // }
}
export default mainRouter
