const mpinRouter = {
  list:{
      path: '/mpin_list',
      name: 'MpinList',
      component: () => import('@/views/StockManagement/MpinManage/MpinList.vue'),
      meta:{
          title: '物料待入库 列表'
      },
  },
  // add: {
  //     path: '/mpin_add',
  //     name: 'MpinAdd',
  //     component: () => import('@/views/PurchaseManagement/MpinManage/MpinAdd.vue'),
  //     meta:{
  //         title: '物料采购 新增'
  //     },
  // },
  // edit: {
  //     path: '/mpin_edit',
  //     name: 'MpinEdit',
  //     component: () => import('@/views/PurchaseManagement/MpinManage/MpinEdit.vue'),
  //     meta:{
  //         title: '物料采购 编辑'
  //     },
  // }
}
export default mpinRouter