const msotRouter = {
  list:{
      path: '/msot_list',
      name: 'MsotList',
      component: () => import('@/views/StockManagement/MsotManage/MsotList.vue'),
      meta:{
          title: '物料出库单 列表'
      },
  },
  add: {
      path: '/msot_add',
      name: 'MsotAdd',
      component: () => import('@/views/StockManagement/MsotManage/MsotAdd.vue'),
      meta:{
          title: '物料出库单 新增'
      },
  },
  edit: {
      path: '/msot_edit',
      name: 'MsotEdit',
      component: () => import('@/views/StockManagement/MsotManage/MsotEdit.vue'),
      meta:{
          title: '物料出库单 编辑'
      },
  }
}
export default msotRouter