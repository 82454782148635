const podrRouter = {
  list:{
      path: '/podr_list',
      name: 'PodrList',
      component: () => import('@/views/PurchaseManagement/PodrManage/PodrList.vue'),
      meta:{
          title: '产品订单 列表'
      },
  },
  add: {
      path: '/podr_add',
      name: 'PodrAdd',
      component: () => import('@/views/PurchaseManagement/PodrManage/PodrAdd.vue'),
      meta:{
          title: '产品订单 新增'
      },
  },
  edit: {
      path: '/podr_edit',
      name: 'PodrEdit',
      component: () => import('@/views/PurchaseManagement/PodrManage/PodrEdit.vue'),
      meta:{
          title: '产品订单 编辑'
      },
  }
}
export default podrRouter