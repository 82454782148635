const factRouter = {
    list:{
        path: '/fact_list',
        name: 'FactList',
        component: () => import('@/views/PurcManagement/FactManage/FactList.vue'),
        meta:{
            title: '工厂采购 列表'
        },
    },
    add: {
        path: '/fact_add',
        name: 'FactAdd',
        component: () => import('@/views/PurcManagement/FactManage/FactAdd.vue'),
        meta:{
            title: '工厂采购 新增'
        },
    },
    edit: {
        path: '/fact_edit',
        name: 'FactEdit',
        component: () => import('@/views/PurcManagement/FactManage/FactEdit.vue'),
        meta:{
            title: '工厂采购 编辑'
        },
    }
}
export default factRouter
