import Vue from 'vue';
import UmyUi from 'umy-ui';
import 'umy-ui/lib/theme-chalk/index.css'; // 引入样式
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueCookies from 'vue-cookies';
import helper from './assets/js/helper.js';
import 'default-passive-events';
import VueCoreVideoPlayer from 'vue-core-video-player';
import './plugins/element.js';
import './assets/css/global.scss';
import './assets/css/icon_font.css';

Vue.use(VueCoreVideoPlayer);

Vue.use(VueAxios, axios);
Vue.use(VueCookies);
Vue.use(UmyUi);
Vue.config.productionTip = false;
Vue.prototype.helper = helper;

Vue.prototype.jump = (p, q) => {
  router.push({ path: p, query: q }).catch(err => err);
};
// Vue.prototype.bus = new Vue()
Vue.prototype.$EventBus = new Vue();
// el-table表头吸顶效果
Vue.directive('sticky', {
  inserted(el, binding) {
    let tSearch = el.getElementsByClassName('tSearch')[0];
    let tbody = el.getElementsByClassName('el-table__body-wrapper')[0];
    let thead = el.getElementsByClassName('el-table__header-wrapper')[0];
    // 获取滚动元素
    const scrollParent = document.querySelector(binding.value.parent);
    scrollParent.addEventListener('scroll', function () {
      const searchBodyHeight = tSearch.clientHeight;
      const headHeight = thead.clientHeight;
      // 获取thead距离顶部的距离
      let searcherTop = tSearch.getBoundingClientRect().top;
      if (searcherTop <= binding.value.top) {
        tbody.style.paddingTop = searchBodyHeight + headHeight + 'px';
        tSearch.style.position = 'fixed';
        thead.style.position = 'fixed';
        tSearch.style.zIndex = '2023';
        thead.style.zIndex = '2023';
        tSearch.style.top = binding.value.top + 'px';
        thead.style.top = searchBodyHeight + binding.value.top + 'px';
        tSearch.style.width = tbody.offsetWidth + 'px';
        thead.style.width = tbody.offsetWidth + 'px';
        tSearch.style.borderTop = '1px solid #EBEBEB';
      }
      // 判断是否需要回归原来位置
      let originally = tbody.getBoundingClientRect().top;
      // 判断底部距离是否超过表头
      let goBeyond = tbody.getBoundingClientRect().bottom;
      if (originally > binding.value.top || goBeyond <= tSearch.offsetHeight) {
        tbody.style.paddingTop = '0';
        tSearch.style.position = 'relative';
        thead.style.position = 'relative';
        tSearch.style.zIndex = '0';
        thead.style.zIndex = '0';
        tSearch.style.top = 0 + 'px';
        thead.style.top = 0 + 'px';
        tSearch.style.width = tbody.offsetWidth + 'px';
        thead.style.width = tbody.offsetWidth + 'px';
        tSearch.style.borderTop = 'none';
        thead.style.borderTop = 'none';
      }
    });
  }
});

router.beforeEach((to, from, next) => {
  if (Vue.prototype.$cookies.get('userInfo')) {
    next();
  } else {
    if (to.fullPath === '/login') {
      next();
    } else {
      next({ path: '/login' });
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
