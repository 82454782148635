const mstfRouter = {
  list:{
      path: '/mstf_list',
      name: 'MstfList',
      component: () => import('@/views/StockManagement/MstfManage/MstfList.vue'),
      meta:{
          title: '物料出入库 列表'
      },
  },
  add: {
      path: '/mstf_add',
      name: 'MstfAdd',
      component: () => import('@/views/StockManagement/MstfManage/MstfAdd.vue'),
      meta:{
          title: '物料入库 新增'
      },
  },
  addO: {
    path: '/mstf_addO',
    name: 'MstfAddO',
    component: () => import('@/views/StockManagement/MstfManage/MstfAddO.vue'),
    meta:{
        title: '物料出库 新增'
    },
},
}
export default mstfRouter