const qmttRouter = {
  list:{
      path: '/qmtt_list',
      name: 'QmttList',
      component: () => import('@/views/InspectManagement/QmttManage/QmttList.vue'),
      meta:{
          title: '二维码金检 列表'
      },
  }
}
export default qmttRouter