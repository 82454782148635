const pconRouter = {
  list:{
      path: '/pcon_list',
      name: 'PconList',
      component: () => import('@/views/ContractManagement/PconManage/PconList.vue'),
      meta:{
          title: '成品合同 列表'
      },
  },
  add: {
      path: '/pcon_add',
      name: 'PconAdd',
      component: () => import('@/views/ContractManagement/PconManage/PconAdd.vue'),
      meta:{
          title: '成品合同 新增'
      },
  },
  edit: {
      path: '/pcon_edit',
      name: 'PconEdit',
      component: () => import('@/views/ContractManagement/PconManage/PconEdit.vue'),
      meta:{
          title: '成品合同 编辑'
      },
  }
}
export default pconRouter