const acctRouter = {
  list:{
    path: '/acct_list',
    name: 'AcctList',
    component: () => import('@/views/SystemManagement/AcctManage/AcctList.vue'),
    meta:{
        title: '账套信息 列表'
    },
  },
  add:{
    path: '/acct_add',
    name: 'AcctAdd',
    component: () => import('@/views/SystemManagement/AcctManage/AcctAdd.vue'),
    meta:{
        title: '账套信息 新增'
    },
  },
  edit:{
    path: '/acct_edit',
    name: 'AcctEdit',
    component: () => import('@/views/SystemManagement/AcctManage/AcctEdit.vue'),
    meta:{
        title: '账套信息 编辑'
    },
  },
}
export default acctRouter;