import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    pageSize: 15,
    expressInfo: [],
    dentSubTable: [],
    selection: { prop: 'selection', type: 'selection', labelWidth: '48px' },
    index: { prop: 'index', type: 'index', labelWidth: '50px', label: '序号' },
    stff_name: { prop: 'stff_name', label: '录入人', itemType: 'input', labelWidth: '120px', sortable: false },
    create_time: {
      prop: 'create_time',
      label: '录入时间',
      itemType: 'date',
      labelWidth: '120px',
      formatter: val => getDateNoTime(val, true)
    },
    status: {
      prop: 'status',
      label: '单据状态',
      itemType: 'select',
      fixed: 'right',
      options: [
        { value: 0, label: '草拟' },
        { value: 1, label: '在批' },
        { value: 2, label: '生效' }
      ]
    },
    dentSubTableBackup: []
  },
  mutations: {
    setExpressInfo(state, val) {
      state.expressInfo = val;
    },
    setDentSubTable(state, val) {
      state.dentSubTable = val;
    },
    setDentSubTableBackup(state, val) {
      state.dentSubTableBackup = val;
    }
  },
  actions: {},
  modules: {}
});
