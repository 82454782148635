const MtqrRouter = {
  list:{
      path: '/Mtqr_list',
      name: 'MtqrList',
      component: () => import('@/views/StockManagement/MtqrManage/MtqrList.vue'),
      meta:{
          title: '物料二维码 列表'
      },
  },
  add: {
      path: '/Mtqr_add',
      name: 'MtqrAdd',
      component: () => import('@/views/StockManagement/MtqrManage/MtqrAdd.vue'),
      meta:{
          title: '物料二维码 新增'
      },
  },
  edit: {
    path: '/Mtqr_edit',
    name: 'MtqrEdit',
    component: () => import('@/views/StockManagement/MtqrManage/TabChild/MtqrEdit.vue'),
    meta:{
        title: '物料二维码 新增'
    },
}, 
}
export default MtqrRouter   