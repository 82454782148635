<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
    }
  },
  methods: {
  }
};
</script>

<style scoped lang="scss">
@import "assets/css/main.css";
@import "assets/css/color-dark.css";
#app { 
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}
::v-deep .el-form-item{
  margin-bottom: 0;
}
body {
  margin: 0px;
}
::v-deep .el-textarea .el-input__count{
  bottom:1px !important;
}
::v-deep .el-checkbox__inner{
      border: 1px solid #080808;
}
::v-deep .el-dialog__body{
  padding: 10px 20px;
}
::v-deep .el-dialog__header {
  padding: 20px 24px 0px;;
}
</style>
