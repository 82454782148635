const dentRouter = {
  list:{
      path: '/dent_list',
      name: 'DentList',
      component: () => import('@/views/PurcManagement/DentManage/DentList.vue'),
      meta:{
          title: '工厂送货 列表'
      },
  },
//   add: {
//       path: '/dent_add',
//       name: 'DentAdd',
//       component: () => import('@/views/PurcManagement/DentManage/DentAdd.vue'),
//       meta:{
//           title: '送货单明细 新增'
//       },
//   },
  edit: {
      path: '/dent_edit',
      name: 'DentEdit',
      component: () => import('@/views/PurcManagement/DentManage/DentEdit.vue'),
      meta:{
          title: '工厂送货 编辑'
      },
  },
  push1: {
    path: '/dent_push_list_9001',
    name: 'DentPush9001',
    component: () => import('@/views/PurcManagement/DentManage/DentPush9001.vue'),
    meta: {
        title: '工厂发货提醒'
    },
  },
}
export default dentRouter
