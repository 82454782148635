const modrRouter = {
    list:{
        path: '/modr_list',
        name: 'ModrList',
        component: () => import('@/views/PurchaseManagement/ModrManage/ModrList.vue'),
        meta:{
            title: '物料订单 列表'
        },
    },
    add: {
        path: '/modr_add',
        name: 'ModrAdd',
        component: () => import('@/views/PurchaseManagement/ModrManage/ModrAdd.vue'),
        meta:{
            title: '物料订单 新增'
        },
    },
    edit: {
        path: '/modr_edit',
        name: 'ModrEdit',
        component: () => import('@/views/PurchaseManagement/ModrManage/ModrEdit.vue'),
        meta:{
            title: '物料订单 编辑'
        },
    }
}
export default modrRouter