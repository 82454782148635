const macpRouter = {
    list: {
        path: '/macp_list',
        name: 'MacpList',
        component: () => import('@/views/InspectManagement/MacpManage/MacpList.vue'),
        meta: {
            title: '机检记录 列表'
        },
    },
    push1: {
        path: '/macp_push_list_9001',
        name: 'MtrlPush9001',
        component: () => import('@/views/InspectManagement/MacpManage/MacpPush9001.vue'),
        meta: {
            title: '机器未检验'
        },
    },
    push2: {
        path: '/macp_push_list_9002',
        name: 'MtrlPush9002',
        component: () => import('@/views/InspectManagement/MacpManage/MacpPush9002.vue'),
        meta: {
            title: '机器异常'
        },
    },
}
export default macpRouter