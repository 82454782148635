const mpotRouter = {
  list:{
      path: '/mpot_list',
      name: 'MpotList',
      component: () => import('@/views/StockManagement/MpotManage/MpotList.vue'),
      meta:{
          title: '物料待出库 列表'
      },
  },
  // add: {
  //     path: '/mpot_add',
  //     name: 'MpotAdd',
  //     component: () => import('@/views/PurchaseManagement/MpotManage/MpotAdd.vue'),
  //     meta:{
  //         title: '物料采购 新增'
  //     },
  // },
  // edit: {
  //     path: '/mpot_edit',
  //     name: 'MpotEdit',
  //     component: () => import('@/views/PurchaseManagement/MpotManage/MpotEdit.vue'),
  //     meta:{
  //         title: '物料采购 编辑'
  //     },
  // }
}
export default mpotRouter