// import VueCookies from 'vue-cookies';
// import Vue from 'vue';
// Vue.use(VueCookies)
// import VueCookies from 'vue-cookies'; import Vue from 'vue'; Vue.use(VueCookies)
import axios from 'axios';

export default {
  //将json或Object转化成FormData
  toFormData(param) {
    let formData = new FormData();
    Object.keys(param).forEach(key => {
      formData.append(key, param[key]);
    });
    return formData;
  },
  // 过滤空格
  // keepSpace
  //保留四位小数
  keepEngNum3(str) {
    return str.replace(/[^0-9-_]/g, '');
  },
  // 限定输入数字和-
  keepNumberH(str) {
    return str.replace(/[^0-9-]/g, '');
  },
  // 只能输入电话
  purephone(str) {
    return str.replace(/[^0-9-]/g, '');
  },
  // 只能输入汉语
  keepChin(str) {
    return str.replace(/[^\u4e00-\u9fa5]/g, '');
  },
  // 过滤只能输入英文
  keepEnglish1(str) {
    return str.replace(/[^a-zA-Z ]/g, '');
  },
  //过滤后只保留英语字母和数字
  keepEngNum(str) {
    return str.replace(/[^0-9a-zA-Z]/g, '');
  },
  //过滤后只保留英语字母和数字和@
  keepEngNumA(str) {
    return str.replace(/[^0-9a-zA-Z@]/g, '');
  },
  //过滤后只保留英语字母和数字和@和-
  keepEngNumABar(str) {
    return str.replace(/[^0-9a-zA-Z@-]/g,'');
  },
  // 过滤只能输入英文
  keepEnglish(str) {
    return str.replace(/[^a-zA-Z]/g, '');
  },
  // 过滤后只保留数字和.
  keepTNum1(number) {
    return number.replace(/[^0-9.]/g, '');
  },
  //过滤后只保留英语字母和数字加-
  keepEngNum1(str) {
    return str.replace(/[^0-9a-zA-Z-]/g, '');
  },
  //过滤后只保留英语字母和数字加-加_
  keepEngNum2(str) {
    return str.replace(/[^0-9a-zA-Z-_]/g, '');
  },
  //过滤后只保留英语字母和数字加-加_
  keepEngNum2T(str) {
    return str.replace(/[^0-9a-zA-Z-_@]/g, '');
  },
  // 限定输入正整数数字 ---用于公积金账号
  keepTNum(number) {
    return number.replace(/\D/g, '');
  },
  // 限定不能输入空格
  keepSpace(number) {
    if (/\s/g.test(number)) {
      return number.substr(0, number.indexOf(' '));
    } else {
      return number;
    }
  },
  //时间戳转换成显示时间(到秒)
  toTime(time) {
    var date = new Date(parseInt(time) * 1000);
    let Y = date.getFullYear() + '-';
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return Y + M + D + h + m + s;
  },
  //时间戳转换成显示时间(到日)
  toTimeDay(time) {
    var date = new Date(parseInt(time) * 1000);
    let Y = date.getFullYear() + '-';
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    return Y + M + D;
  },
  // 国标时间转成时间戳 10 位
  toTimeVal(localTime) {
    return (localTime = Math.round(new Date().getTime() / 1000));
  },

  // 获取时间开始时间和结束时间戳
  getTime(timeVal) {
    if (timeVal && timeVal.length === 2) {
      timeVal.startTime = timeVal[0];
      timeVal.endTime = timeVal[1];
      timeVal.startTime = Number(new Date(timeVal.startTime).getTime() / 1000);
      timeVal.endTime = Number(new Date(timeVal.endTime).getTime() / 1000);
      return timeVal;
    } else {
      timeVal = [];
      return timeVal;
    }
  },
  // 离职司龄
  toLeaveSen(entryDate, leaveDate) {
    entryDate = new Date(entryDate * 1000);
    leaveDate = new Date(leaveDate * 1000);
    if (leaveDate > entryDate) {
      let year = leaveDate.getFullYear() - entryDate.getFullYear();
      let month = leaveDate.getMonth() - entryDate.getMonth();
      if (month < 0) {
        year--;
        month = leaveDate.getMonth() + (12 - entryDate.getMonth());
      }
      if (year === 0 && month === 0) {
        return (entryDate = '未满一个月');
      } else if (year === 0 && month > 0) {
        return (entryDate = month + '个月');
      }
      return (entryDate = year + '年 ' + month + '个月');
    } else {
      return (entryDate = '离职日期小于现在时间!');
    }
  },

  // 计算司龄
  toSeniority(entryDate, leaveDate) {
    // if(leaveDate !==0){
    //  return entryDate = this.toLeaveSen(entryDate,leaveDate)
    // }
    let nowDate = new Date().getTime();
    entryDate = new Date(entryDate * 1000).getTime();
    if (leaveDate !== 0) {
      nowDate = new Date(leaveDate * 1000).getTime();
    }
    if (nowDate == entryDate) {
      return '未满一个月';
    }
    if (nowDate > entryDate) {
      let workDate = (nowDate - entryDate) / (60 * 60 * 24 * 30 * 1000);
      if (workDate >= 12) {
        // 一年以上
        if (workDate % 12 === 0) {
          return parseInt(workDate / 12) + '年';
        } else {
          return parseInt(workDate / 12) + '年' + parseInt(workDate % 12) + '个月';
        }
      } else if (parseInt(workDate) > 0) {
        return parseInt(workDate) + '个月';
      } else {
        return '未满一个月';
      }
    } else {
      return '日期填写不正确!';
    }
  },

  // 根据身份证号计算出生日期，年龄，性别
  mathUserInfo(stff_identity, num, birth, gender, age) {
    if (stff_identity.length === 18) {
      if (num === 1) {
        birth = stff_identity.substring(6, 10) + '-' + stff_identity.substring(10, 12) + '-' + stff_identity.substring(12, 14);
        return birth;
      }
      if (num === 2) {
        if (parseInt(stff_identity.substr(-2, 1)) % 2 === 1) {
          return (gender = '男');
        } else {
          return (gender = '女');
        }
      }
      if (num === 3) {
        var myDate = new Date();
        var month = myDate.getMonth() + 1;
        var day = myDate.getDate();
        age = myDate.getFullYear() - stff_identity.substring(6, 10) - 1;
        if (
          stff_identity.substring(10, 12) < month ||
          (stff_identity.substring(10, 12) === month && stff_identity.substring(12, 14) <= day)
        ) {
          age++;
        }
        return age;
      }
    } else {
      return (age = null), (birth = null), (gender = null);
    }
  },

  // 获取单个日期
  getNewDate(timeVal) {
    if (timeVal) {
      timeVal = Number(new Date(timeVal).getTime() / 1000);
      return timeVal;
    } else {
      timeVal = null;
      return timeVal;
    }
  },
  // 时间转换为显示时间终极版
  toStringDate(rowVal) {
    if (rowVal) {
      return this.toTime(rowVal);
    }
  },
  toStringDay(rowVal) {
    if (rowVal) {
      return this.toTimeDay(rowVal);
    }
  },
  // 数字字符串转成数组
  toArray(strArr) {
    strArr = strArr.split(',').map(Number);
    return strArr;
  },
  // 转换合同状态
  getStatusName(status) {
    if (status === 0) {
      return { name: '草拟', type: 'info' };
    } else if (status === 1) {
      return { name: '在批', type: 'warning' };
    } else {
      return { name: '生效', type: 'success' };
    }
  },
  //转换操作状态
  getInstAction(appr_inst_action) {
    if (appr_inst_action === 1) {
      return { name: '提交', type: 'primary' };
    } else if (appr_inst_action === 2) {
      return { name: '通过', type: 'success' };
    } else {
      return { name: '拒绝', type: 'danger' };
    }
  },
  //转换下一节点状态
  getInstNextNode(appr_inst_next_node) {
    if (appr_inst_next_node === -1) {
      return { name: '草拟', type: 'info' };
    } else if (appr_inst_next_node === 0) {
      return { name: '生效', type: 'success' };
    } else {
      return { name: '在批', type: 'warning' };
    }
  },
  // 拼接图片url地址
  picUrl(url, type, updateTimes, kind) {
    let updateTime = updateTimes ?? new Date().getTime();
    let header = '';
    if (url.indexOf('aliyun') !== -1) return url;
    switch (process.env.VUE_APP_ENV) {
      case 'production':
        header = 'https://www.manenmes.com';
        break;
      case 'development':
        header = 'http://192.168.1.236';
        break;
      case 'test':
        header = 'https://www.petsprod.com';
        break;
    }
    if (kind === 1) {
      switch (type) {
        case 'l':
          return (header = header + '/dyjfile' + url + '.jpg?' + updateTime);
        case 'm':
          return (header = header + '/dyjfile' + url + '_m.jpg?' + updateTime);
        case 's':
          return (header = header + '/dyjfile' + url + '_s.jpg?' + updateTime);
      }
    } else {
      switch (type) {
        case 'l':
          return (url = header + '/mesfile' + url + '.jpg?' + updateTime);
        case 'm':
          return (url = header + '/mesfile' + url + '_m.jpg?' + updateTime);
        case 's':
          return (url = header + '/mesfile' + url + '_s.jpg?' + updateTime);
      }
    }
  },
  // base64转blob
  //base64转成blob
  dataURLtoFile(data, fileName) {
    const dataArr = data.split(',');
    const byteString = atob(dataArr[1]);
    const options = {
      type: 'image/png',
      endings: 'native'
    };
    const u8Arr = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      u8Arr[i] = byteString.charCodeAt(i);
    }
    let formData = new FormData();
    let fileOfBlob = new File([u8Arr], fileName + '.png', options); //返回文件流
    formData.append('file', fileOfBlob);
    return formData;
  },
  //将blob转为file
  uploadImg(fileData) {
    let formData = new FormData();
    let fileOfBlob = new File([fileData], new Date() + '.png'); // 命名图片名
    formData.append('file', fileOfBlob);
    console.log('fileData', fileData);
    return formData;
  },
  // 相关文档图片拼接地址
  picUrlDoc(url, type, updateTime, kind) {
    // this.picUrl(url, type,updateTime,kind)
    // let updateTime = updateTimes ?? new Date().getTime()
    let header = '';
    switch (process.env.VUE_APP_ENV) {
      case 'production':
        header = 'https://www.manenwork.com';
        break;
      case 'development':
        header = 'http://192.168.1.236';
        break;
      case 'test':
        header = 'https://www.petsprod.com';
        break;
    }
    if (kind === 1) {
      switch (type) {
        case 'l':
          return (header = header + '/dyjfile' + url + '?' + updateTime);
        case 'm':
          return (header = header + '/dyjfile' + url + '?' + updateTime);
        case 's':
          return (header = header + '/dyjfile' + url + '?' + updateTime);
      }
    } else {
      switch (type) {
        case 'l':
          return (url = header + '/mesfile' + url + '?' + updateTime);
        case 'm':
          return (url = header + '/mesfile' + url + '?' + updateTime);
        case 's':
          return (url = header + '/mesfile' + url + '?' + updateTime);
      }
    }
  },
  // 相关文档拼接视频地址
  pVideoUrl(url, kind) {
    if (kind === 1) {
      switch (process.env.VUE_APP_ENV) {
        case 'production':
          return 'https://www.manenmes.com/dyjfile' + url;
        case 'development':
          return 'http://192.168.1.236/dyjfile' + url;
        case 'test':
          return 'https://www.petsprod.com/dyjfile' + url;
      }
    } else {
      return (url = '/mesfile' + url);
    }
  },
  // 机检小程序码路径
  machineInspection(val, val1) {
    let str = 'code=' + val + ';' + 'id=' + val1;
    switch (process.env.VUE_APP_ENV) {
      case 'production':
        return `https://www.manenmes.com/wxmesapi/user/get_qr_code?data=${str}&page_path=pages/machineCheckInfo/machineCheckInfo`;
      case 'development':
        return `http://192.168.1.236:8088/user/get_qr_code?data=${str}&page_path=pages/machineCheckInfo/machineCheckInfo`;
      case 'test':
        return `https://www.petsprod.com/wxmesapi/user/get_qr_code?data=${str}&page_path=pages/machineCheckInfo/machineCheckInfo`;
    }

    // if (process.env.NODE_ENV === "production") {
    //   // return `https://www.manenmes.com/wxmesapi/user/get_qr_code?data=${str}&page_path=pages/machineCheckInfo/machineCheckInfo`
    //   return `https://www.petsprod.com/wxmesapi/user/get_qr_code?data=${str}&page_path=pages/machineCheckInfo/machineCheckInfo`
    //   // return `http://192.168.1.236:8088/user/get_qr_code?data=${str}&page_path=pages/machineCheckInfo/machineCheckInfo`
    // }else {
    //   // return `https://www.manenmes.com/wxmesapi/user/get_qr_code?data=${str}&page_path=pages/machineCheckInfo/machineCheckInfo`
    //   return `https://www.petsprod.com/wxmesapi/user/get_qr_code?data=${str}&page_path=pages/machineCheckInfo/machineCheckInfo`
    //   // return `http://192.168.1.236:8088/user/get_qr_code?data=${str}&page_path=pages/machineCheckInfo/machineCheckInfo`
    // }
  },
  // 组合路径
  megPath(url, kind) {
    if (kind === 1) {
      let header = '/file';
      if (url) {
        return (url = header + url);
      } else {
        return (url = header);
      }
    } else {
      let header = '/mesfile';
      if (url) {
        return (url = header + url);
      } else {
        return (url = header);
      }
    }
  },
  // 下载文件
  downloadItem(url, label, kind) {
    if (kind === 1) {
      axios
        .get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data]);
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    } else {
      axios
        .get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data]);
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    }
  },
  // 下载文件
  downloadItemCopy(url, label) {
    let totalUrl = url;
    let DownloadLink = document.createElement('a');
    DownloadLink.style = 'display: none'; // 创建一个隐藏的a标签
    DownloadLink.download = label;
    DownloadLink.href = totalUrl;
    document.body.appendChild(DownloadLink);
    DownloadLink.click(); // 触发a标签的click事件
    document.body.removeChild(DownloadLink);
  },
  // 聘用类型
  reHireTap(type) {
    if (type === 1) {
      return '返聘';
    } else if (type === 2) {
      return '实习';
    } else if (type === 3) {
      return '劳务';
    } else if (type === 4) {
      return '正式';
    } else {
      return '无生效合同';
    }
  },
  // 保留两位小数
  reservedDigits(value) {
    if (value || value === 0) {
      value = value.toString();
      let ind = value.toString().indexOf('.');
      if (ind < 0) {
        return value + '.00';
      } else {
        if (value.length - 1 - ind > 2) {
          return value.slice(0, ind + 3);
        } else if (value.length - 1 - ind < 2) {
          let index = 2 - (value.length - 1 - ind);
          for (let i = 0; i < index; i++) {
            value = value + '0';
          }
          return value;
        } else {
          return value;
        }
      }
    }
  },
  // 保留3位小数
  reservedThree(value) {
    if (value || value === 0) {
      value = value.toString();
      let ind = value.toString().indexOf('.');
      if (ind < 0) {
        return value + '.000';
      } else {
        if (value.length - 1 - ind > 3) {
          return value.slice(0, ind + 3);
        } else if (value.length - 1 - ind < 3) {
          let index = 3 - (value.length - 1 - ind);
          for (let i = 0; i < index; i++) {
            value = value + '0';
          }
          return value;
        } else {
          return value;
        }
      }
    }
  },
  // 保留小数位数
  retain(num, posi = 0) {
    let num1 = 0;
    let fre = null;
    if (posi % 3 === 1) {
      fre = Math.pow(10, posi + 1);
    } else {
      fre = Math.pow(10, posi);
    }
    num1 = Math.floor(num * fre) / fre;
    if (isNaN(num1)) {
      return 0;
    } else {
      return num1;
    }
  },
  // 保留小数位数且不能超出位数
  retain1(num, posi = 0, str) {
    let num1 = 0;
    let fre = null;
    if (posi % 3 === 1) {
      fre = Math.pow(10, posi + 1);
    } else {
      fre = Math.pow(10, posi);
    }
    num1 = Math.floor(num * fre) / fre;
    if (isNaN(num1)) {
      return 0;
    } else {
      if (num1 >= str) {
        return str - 0.01;
      } else {
        let value = num1;
        value = value.toString();
        if (value) {
          let ind = value.toString().indexOf('.');
          if (ind < 0) {
            return value + '.00';
          } else {
            if (value.length - 1 - ind > 2) {
              return value.substr(0, ind + 3);
            } else if (value.length - 1 - ind < 2) {
              let index = 2 - (value.length - 1 - ind);
              for (let i = 0; i < index; i++) {
                value = value + '0';
              }
              return value;
            } else {
              return value;
            }
          }
        }
      }
    }
  },
  // 计算价格保留四位
  calcPrice(num, posi = 0, str) {
    let num1 = 0;
    let fre = null;
    if (posi % 3 === 1) {
      fre = Math.pow(10, posi + 1);
    } else {
      fre = Math.pow(10, posi);
    }
    num1 = (num * fre) / fre;
    if (isNaN(num1)) {
      return 0.0;
    } else {
      if (num1 >= str) {
        return str - 0.0001;
      } else {
        let value = num1;
        value = value.toString();
        if (value) {
          let ind = value.toString().indexOf('.');
          if (ind < 0) {
            return value + '.0000';
          } else {
            if (value.length - 1 - ind > 4) {
              return value.substr(0, ind + 5);
            } else if (value.length - 1 - ind < 4) {
              let index = 4 - (value.length - 1 - ind);
              for (let i = 0; i < index; i++) {
                value = value + '0';
              }
              return value;
            } else {
              return value;
            }
          }
        }
      }
    }
  },
  // 计算位数保留四位
  haveFour(value) {
    if (value || value === 0) {
      value = value.toString();
      let ind = value.toString().indexOf('.');
      if (ind < 0) {
        return value + '.0000';
      } else {
        if (value.length - 1 - ind > 4) {
          return value.slice(0, ind + 5);
        } else if (value.length - 1 - ind < 4) {
          let index = 4 - (value.length - 1 - ind);
          for (let i = 0; i < index; i++) {
            value = value + '0';
          }
          return value;
        } else {
          return value;
        }
      }
    } else {
      return '0.0000';
    }
  },

  // 控制状态删除
  isDelete(selection) {
    let statusList = selection.map(item => {
      return item.status;
    });
    const allStatus = statusList.reduce(function (sum, number) {
      return sum + number;
    }, 0);
    if (allStatus > 0) {
      return (selection = false);
    } else {
      return (selection = true);
    }
  },
  modePrepend(val) {
    if (process.env.NODE_ENV === 'production') {
      return '/mes' + val;
    } else {
      return val;
    }
  },

  // 编辑删除保存
  deleteSaveBody(formMain, formMainList, formCopyList, no) {
    let delete_time = this.toTimeVal();
    let formLi = [];
    let form = {};
    form = Object.assign(form, formMain);
    formLi = Object.assign(formLi, formCopyList);
    for (let i = formLi.length - 1; i >= 0; i--) {
      if (formMainList.indexOf(formLi[i]) !== -1) {
        formLi.splice(i, 1);
      }
    }
    if (no === 1) {
      for (let i = 0; i < formLi.length; i++) {
        formLi[i].destroy_flag = 1;
      }
    } else if (no === 2) {
      for (let i = 0; i < formLi.length; i++) {
        formLi[i].delete_time = delete_time;
      }
    }
    return (formMainList = formLi);
  },
  // 不用切图片
  picUrlNo(url) {
    let updateTime = new Date().getTime();
    return (url = '/mesfile' + url + '.jpg?' + updateTime);
  },

  // 深拷贝
  deepCopy(oldObj) {
    let newObj = oldObj instanceof Array ? [] : {};
    for (let key in oldObj) {
      // oldobj是数组 key 为索引，是对象为属性值
      // 判断值是否是原始类型
      if (typeof oldObj[key] !== 'object') {
        newObj[key] = oldObj[key];
      } else {
        newObj[key] = this.deepCopy(oldObj[key]);
      }
    }
    return newObj;
  },

  // list显示录入人组合字符串
  personCombina(scope) {
    let stffName = scope.stff_name;
    let deptName = scope.dept_name;
    let deptTeamName = scope.dept_team_name;
    let cpttName = scope.cptt_name;
    if (stffName === null) {
      stffName = '';
    }
    if (deptName === null) {
      deptName = '';
    }
    if (deptTeamName === null) {
      deptTeamName = '';
    }
    if (cpttName === null) {
      cpttName = '';
    }
    let combination = stffName + '(' + cpttName + ' ' + deptName + ' ' + deptTeamName + ')';
    return combination;
  },
  unique(arr) {
    var newArr = [];
    for (var i = 0; i < arr.length; i++) {
      for (var j = i + 1; j < arr.length; j++) {
        if (arr[i] == arr[j]) {
          ++i;
        }
      }
      newArr.push(arr[i]);
    }
    return newArr;
  }
};
