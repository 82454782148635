const podtRouter = {
  list:{
      path: '/podr_prod_list',
      name: 'PodtList',
      component: () => import('@/views/ProduceManagement/PodtManage/PodtList.vue'),
      meta:{
          title: '产品总控表 列表'
      },
  },
  edit: {
      path: '/podt_edit',
      name: 'PodtEdit',
      component: () => import('@/views/ProduceManagement/PodtManage/PodtEdit.vue'),
      meta:{
          title: '产品工单'
      },
  }
}
export default podtRouter