const smplRouter = {
  list:{
      path: '/smpl_list',
      name: 'SmplList',
      component: () => import('@/views/DevelopManagement/SmplManage/SmplList.vue'),
      meta:{
          title: '样品工单 列表'
      },
  },
  add: {
      path: '/smpl_add',
      name: 'SmplAdd',
      component: () => import('@/views/DevelopManagement/SmplManage/SmplAdd.vue'),
      meta:{
          title: '样品工单 新增'
      },
  },
  edit: {
      path: '/smpl_edit',
      name: 'SmplEdit',
      component: () => import('@/views/DevelopManagement/SmplManage/SmplEdit.vue'),
      meta:{
          title: '样品工单 编辑'
      },
  }
}
export default smplRouter