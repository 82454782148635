const psotRouter = {
  list:{
      path: '/psot_list',
      name: 'PsotList',
      component: () => import('@/views/StockManagement/PsotManage/PsotList.vue'),
      meta:{
          title: '成品出库单 列表'
      },
  },
  add: {
      path: '/psot_add',
      name: 'PsotAdd',
      component: () => import('@/views/StockManagement/PsotManage/PsotAdd.vue'),
      meta:{
          title: '成品出库单 新增'
      },
  },
  edit: {
      path: '/psot_edit',
      name: 'PsotEdit',
      component: () => import('@/views/StockManagement/PsotManage/PsotEdit.vue'),
      meta:{
          title: '成品出库单 编辑'
      },
  }
}
export default psotRouter