const prodRouter = {
  list:{
      path: '/prod_list',
      name: 'ProdList',
      component: () => import('@/views/DevelopManagement/ProdManage/ProdList.vue'),
      meta:{
          title: '产品信息 列表'
      },
  },
  add: {
      path: '/prod_add',
      name: 'ProdAdd',
      component: () => import('@/views/DevelopManagement/ProdManage/ProdAdd.vue'),
      meta:{
          title: '产品信息 新增'
      },
  },
  edit: {
      path: '/prod_edit',
      name: 'ProdEdit',
      component: () => import('@/views/DevelopManagement/ProdManage/ProdEdit.vue'),
      meta:{
          title: '产品信息 编辑'
      },
  }
}
export default prodRouter