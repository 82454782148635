const mtrlRouter = {
    list:{
        path: '/mtrl_list',
        name: 'MtrlList',
        component: () => import('@/views/DevelopManagement/MtrlManage/MtrlList.vue'),
        meta:{
            title: '物料信息 列表'
        },
    },
    add: {
        path: '/mtrl_add',
        name: 'MtrlAdd',
        component: () => import('@/views/DevelopManagement/MtrlManage/MtrlAdd.vue'),
        meta:{
            title: '物料信息 新增'
        },
    },
    edit: {
        path: '/mtrl_edit',
        name: 'MtrlEdit',
        component: () => import('@/views/DevelopManagement/MtrlManage/MtrlEdit.vue'),
        meta:{
            title: '物料信息 编辑'
        },
    }
}
export default mtrlRouter