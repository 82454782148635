const OpenRouter = {
    list:{
        path: '/open_list',
        name: 'OpenList',
        component: () => import('@/views/ProduceManagement/openManage/OpenCartList.vue'),
        meta:{
            title: '开卡 列表'
        },
    },
    add: {
        path: '/open_add',
        name: 'OpenAdd',
        component: () => import('@/views/ProduceManagement/openManage/OpenCartAdd.vue'),
        meta:{
            title: '开卡 新增'
        },
    },
    edit: {
        path: '/open_edit',
        name: 'OpenEdit',
        component: () => import('@/views/ProduceManagement/openManage/OpenCartEdit.vue'),
        meta:{
            title: '开卡 编辑'
        },
    },
    addFill :{
        path: '/open_addFill',
        name: 'openAddFill',
        component: () => import('@/views/ProduceManagement/openManage/OpenFillCartAdd.vue'),
        meta:{
            title: '补卡 新增'
        },
    }
}
export default OpenRouter