const fcreRouter = {
    list:{
        path: '/fcre_list',
        name: 'FcreList',
        component: () => import('@/views/PurcManagement/FcreManage/FcreList.vue'),
        meta:{
            title: '工厂收货单 列表'
        },
    },
    push1: {
        path: '/fcre_push_list_9001',
        name: 'FcrePush9001',
        component: () => import('@/views/PurcManagement/FcreManage/FcrePush9001.vue'),
        meta: {
            title: '工厂收货提醒'
        },
    },
}
export default fcreRouter
