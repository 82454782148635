const mainRouter = {
    list:{
        path: '/mdrp_list',
        name: 'MdrpList',
        component: () => import('@/views/InspectManagement/MdrpManage/MdrpList.vue'),
        meta:{
            title: '工序金检测记录 列表'
        },
    },
}
export default mainRouter
