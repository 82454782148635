import Vue from 'vue';
import VueRouter from 'vue-router';
import { stringifyQuery, parseQuery } from './utils/query';

Vue.use(VueRouter);

/**
 * 重写路由的push方法
 * 解决NavigationDuplicated问题
 */
// const routerPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return routerPush.call(this, location).catch(error => error)
// }
/*Router Modules*/
import cpttRouter from '@/router/modules/cpttRouter';
import deptRouter from '@/router/modules/deptRouter';
import stffRouter from '@/router/modules/stffRouter';
import roleRouter from '@/router/modules/roleRouter';
import permRouter from '@/router/modules/permRouter';
import userRouter from '@/router/modules/userRouter';
import apprRouter from '@/router/modules/apprRouter';
import acctRouter from '@/router/modules/acctRouter';
import crftRouter from '@/router/modules/crftRouter';
import mtrlRouter from '@/router/modules/mtrlRouter';
import optnRouter from '@/router/modules/optnRouter';
import smplRouter from '@/router/modules/smplRouter';
import myacRouter from '@/router/modules/myacRouter';
import prodRouter from '@/router/modules/prodRouter';
import podrRouter from '@/router/modules/podrRouter';
import modrRouter from '@/router/modules/modrRouter';
import pconRouter from '@/router/modules/pconRouter';
import mconRouter from '@/router/modules/mconRouter';
import ppotRouter from '@/router/modules/ppotRouter';
import ppinRouter from '@/router/modules/ppinRouter';
import mpinRouter from '@/router/modules/mpinRouter';
import mpotRouter from '@/router/modules/mpotRouter';
import psotRouter from '@/router/modules/psotRouter';
import psinRouter from '@/router/modules/psinRouter';
import msotRouter from '@/router/modules/msotRouter';
import msinRouter from '@/router/modules/msinRouter';
import pstkRouter from '@/router/modules/pstkRouter';
import mstkRouter from '@/router/modules/mstkRouter';
import mstfRouter from '@/router/modules/mstfRouter';
import mtqrRouter from '@/router/modules/mtqrRouter';
import podtRouter from '@/router/modules/podtRouter';
import openRouter from '@/router/modules/openRouter';
import qmttRouter from '@/router/modules/qmttRouter';
import macpRouter from '@/router/modules/macpRouter';
import ShweRouter from '@/router/modules/shweRouter';
import mtrbRouter from '@/router/modules/mtrbRouter';
import suppRouter from '@/router/modules/suppRouter';
import custRouter from '@/router/modules/custRouter';
import matrRouter from '@/router/modules/matrRouter';
import IQCRRouter from '@/router/modules/iqcrRouter';
import dentRouter from '@/router/modules/dentRouter';
import mainRouter from '@/router/modules/mainRouter';
import mdrpRouter from '@/router/modules/mdrpRouter';
import factRouter from '@/router/modules/factRouter';
import FcreRouter from '@/router/modules/fcreRouter';
import qrclRouter from '@/router/modules/qrclRouter';
import popuRouter from '@/router/modules/popuRouter';

const routes = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/',
    component: () => import('@/components/common/Home.vue'),
    children: [
      {
        path: '/dashboard',
        component: () => import('@/views/Dashboard.vue'),
        meta: { title: '系统首页' }
      },
      cpttRouter.list,
      cpttRouter.add,
      cpttRouter.edit,
      deptRouter.list,
      deptRouter.add,
      deptRouter.edit,
      stffRouter.list,
      stffRouter.add,
      stffRouter.edit,
      stffRouter.push,
      roleRouter.list,
      roleRouter.edit,
      userRouter.list,
      userRouter.add,
      userRouter.edit,
      apprRouter.list,
      apprRouter.edit,
      apprRouter.toApproved,
      apprRouter.beApproved,
      permRouter.list,
      acctRouter.list,
      acctRouter.add,
      acctRouter.edit,
      crftRouter.list,
      crftRouter.add,
      crftRouter.edit,
      mtrlRouter.list,
      mtrlRouter.add,
      mtrlRouter.edit,
      optnRouter.list,
      optnRouter.edit,
      myacRouter.list,
      smplRouter.list,
      smplRouter.add,
      smplRouter.edit,
      prodRouter.list,
      prodRouter.add,
      prodRouter.edit,
      podrRouter.list,
      podrRouter.add,
      podrRouter.edit,
      modrRouter.list,
      modrRouter.add,
      modrRouter.edit,
      pconRouter.list,
      pconRouter.add,
      pconRouter.edit,
      mconRouter.list,
      mconRouter.edit,
      ppotRouter.list,
      ppinRouter.list,
      mpinRouter.list,
      mpotRouter.list,
      psotRouter.list,
      psotRouter.add,
      psotRouter.edit,
      psinRouter.list,
      psinRouter.add,
      psinRouter.edit,
      msotRouter.list,
      msotRouter.add,
      msotRouter.edit,
      msinRouter.list,
      msinRouter.add,
      msinRouter.edit,
      pstkRouter.list,
      mstkRouter.list,
      mstfRouter.list,
      mstfRouter.add,
      mstfRouter.addO,
      mtqrRouter.list,
      mtqrRouter.add,
      mtqrRouter.edit,
      podtRouter.list,
      podtRouter.edit,
      openRouter.list,
      openRouter.add,
      openRouter.edit,
      openRouter.addFill,
      matrRouter.list,
      matrRouter.add,
      matrRouter.edit,
      matrRouter.push1,
      matrRouter.push2,
      mainRouter.list,
      mdrpRouter.list,
      qmttRouter.list,
      macpRouter.list,
      macpRouter.push1,
      macpRouter.push2,
      ShweRouter.list,
      ShweRouter.shweDetail,
      ShweRouter.push1,
      ShweRouter.push2,
      ShweRouter.push3,
      mtrbRouter.list,
      mtrbRouter.add,
      mtrbRouter.edit,
      suppRouter.list,
      suppRouter.add,
      suppRouter.edit,
      custRouter.list,
      custRouter.add,
      custRouter.edit,
      IQCRRouter.list,
      FcreRouter.list,
      FcreRouter.push1,
      dentRouter.list,
      dentRouter.edit,
      dentRouter.push1,
      factRouter.list,
      factRouter.add,
      factRouter.edit,
      qrclRouter.list,
      popuRouter.list,
      popuRouter.edit,
      popuRouter.push1,
      {
        path: '/about',
        name: 'About',
        component: () => import('@/views/About.vue'),
        meta: { title: '关于' }
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: { title: '登录' }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  stringifyQuery: stringifyQuery, // 序列化query参数
  parseQuery: parseQuery, // 反序列化query参数
  routes
});
export default router;
