const psinRouter = {
  list:{
      path: '/psin_list',
      name: 'PsinList',
      component: () => import('@/views/StockManagement/PsinManage/PsinList.vue'),
      meta:{
          title: '成品入库单 列表'
      },
  },
  add: {
      path: '/psin_add',
      name: 'PsinAdd',
      component: () => import('@/views/StockManagement/PsinManage/PsinAdd.vue'),
      meta:{
          title: '成品入库单 新增'
      },
  },
  edit: {
      path: '/psin_edit',
      name: 'PsinEdit',
      component: () => import('@/views/StockManagement/PsinManage/PsinEdit.vue'),
      meta:{
          title: '成品入库单 编辑'
      },
  }
}
export default psinRouter