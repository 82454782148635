const pstkRouter = {
  list:{
      path: '/pstk_list',
      name: 'PstkList',
      component: () => import('@/views/StockManagement/PstkManage/PstkList.vue'),
      meta:{
          title: '成品库存表 列表'
      },
  },
  // add: {
  //     path: '/pstk_add',
  //     name: 'PstkAdd',
  //     component: () => import('@/views/StockManagement/PstkManage/PstkAdd.vue'),
  //     meta:{
  //         title: '成品出库单 新增'
  //     },
  // },
  // edit: {
  //     path: '/pstk_edit',
  //     name: 'PstkEdit',
  //     component: () => import('@/views/StockManagement/PstkManage/PstkEdit.vue'),
  //     meta:{
  //         title: '成品出库单 编辑'
  //     },
  // }
}
export default pstkRouter