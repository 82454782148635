const optnRouter = {
    list:{
        path: '/optn_list',
        name: 'OptnList',
        component: () => import('@/views/SystemManagement/OptnManage/OptnList.vue'),
        meta:{
            title: '数据中心 列表'
        },
    },
    edit: {
        path: '/optn_edit',
        name: 'OptnEdit',
        component: () => import('@/views/SystemManagement/OptnManage/OptnEdit.vue'),
        meta:{
            title: '数据中心 编辑'
        },
    }
}
export default optnRouter