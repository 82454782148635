const iqcrRouter = {
    list:{
        path: '/iqcr_list',
        name: 'IqcrList',
        component: () => import('@/views/InspectManagement/IQCRManage/IQCRecordList.vue'),
        meta:{
            title: '抽检记录 列表'
        },
    },
}
export default iqcrRouter
