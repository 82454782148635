const crftRouter = {
    list:{
        path: '/crft_list',
        name: 'CrftList',
        component: () => import('@/views/DevelopManagement/CrftManage/CrftList.vue'),
        meta:{
            title: '工艺信息 列表'
        },
    },
    add: {
        path: '/crft_add',
        name: 'CrftAdd',
        component: () => import('@/views/DevelopManagement/CrftManage/CrftAdd.vue'),
        meta:{
            title: '工艺信息 新增'
        }, 
    },
    edit: {
        path: '/crft_edit',
        name: 'CrftEdit',
        component: () => import('@/views/DevelopManagement/CrftManage/CrftEdit.vue'),
        meta:{
            title: '工艺信息 编辑'
        },
    }
}
export default crftRouter
