const mconRouter = {
  list:{
      path: '/mcon_list',
      name: 'MconList',
      component: () => import('@/views/ContractManagement/MconManage/MconList.vue'),
      meta:{
          title: '物料合同 列表'
      },
  },
  edit: {
      path: '/mcon_edit',
      name: 'MconEdit',
      component: () => import('@/views/ContractManagement/MconManage/MconEdit.vue'),
      meta:{
          title: '物料合同 编辑'
      },
  }
}
export default mconRouter