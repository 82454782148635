const userRouter = {
    list:{
        path: '/user_list',
        name: 'UserList',
        component: () => import('@/views/SystemManagement/UserManage/UserList.vue'),
        meta:{
            title: '账号信息 列表'
        },
    },
    add: {
        path: '/user_add',
        name: 'UserAdd',
        component: () => import('@/views/SystemManagement/UserManage/UserAdd.vue'),
        meta:{
            title: '账号信息 新增'
        },
    },
    edit: {
        path: '/user_edit',
        name: 'UserEdit',
        component: () => import('@/views/SystemManagement/UserManage/UserEdit.vue'),
        meta:{
            title: '账号信息 编辑'
        },
    }
}
export default userRouter
